/* eslint-disable import/prefer-default-export */

import { css } from '@emotion/react';

import { bodySpacing, fluid, mq } from '../../style-tokens';

export const contentContainer = css``;

export const sticky = css`
  background-color: white;
  min-height: 100vh;
  padding: 4rem ${bodySpacing} ${bodySpacing} ${bodySpacing};

  @media ${mq.tablet} {
    position: sticky;
    top: ${bodySpacing};
  }
`;

export const privacyContainer = css`
  color: white;
  padding-bottom: ${bodySpacing};
  padding-top: ${bodySpacing};
`;

export const logos = css`
  margin-bottom: ${fluid(0.5, 1)};
  margin-top: ${fluid(0.5, 1)};
  width: 100%;
`;
