import { graphql } from 'gatsby';
import React from 'react';

import Imprint from '../components/Imprint';
import Layout from '../components/Layout';

import { Provider as ContextProvider } from '../context';

import { colors } from '../style-tokens';

export const query = graphql`
  query {
    imprint: markdownRemark(
      fileAbsolutePath: { regex: "/content/pages/imprint.md$/" }
    ) {
      ...Imprint
    }

    privacy: markdownRemark(
      fileAbsolutePath: { regex: "/content/pages/privacy.md$/" }
    ) {
      ...Privacy
    }
  }
`;

const ImprintPage = ({ data }) => (
  <ContextProvider value={{}}>
    <Layout menuPadded={colors.blue} logoColor="black">
      <Imprint {...data} />
    </Layout>
  </ContextProvider>
);

export default ImprintPage;
