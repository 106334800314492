import { graphql } from 'gatsby';
import React from 'react';
import { useTitle } from 'hooked-head';

import Columns from '../Columns';
import Headline from '../Headline';
import HTML from '../HTML';
import Paragraph from '../Paragraph';

import Logos from '../../../static/icons/logos-gefoerdert.svg';

import {
  contentContainer,
  privacyContainer,
  sticky,
  logos,
} from './imprint.style';

export const fragment = graphql`
  fragment Imprint on MarkdownRemark {
    frontmatter {
      intro
      title
    }

    html
  }

  fragment Privacy on MarkdownRemark {
    frontmatter {
      title
    }

    html
  }
`;

const Imprint = ({ imprint, privacy }) => {
  useTitle(imprint.frontmatter.title);

  return (
    <Columns>
      <div css={contentContainer}>
        <div css={sticky}>
          <Headline theme="h2" heavy>
            {imprint.frontmatter.title}
          </Headline>

          <Paragraph>{imprint.frontmatter.intro}</Paragraph>

          <Logos css={logos} />

          <HTML html={imprint.html} />
        </div>
      </div>

      <div css={privacyContainer}>
        <Headline theme="h2" heavy>
          {privacy.frontmatter.title}
        </Headline>

        <HTML html={privacy.html} />
      </div>
    </Columns>
  );
};

export default Imprint;
